import { landingLayoutContextId } from 'components/layout/MambaLayout/Context'
import { CreateBaseUrl } from 'components/layout/MambaLayout/CreateContext'
import {
  cityListPath,
  shortcutListPath,
} from 'components/page/Boarding/boarding.paths'
import { unauthorizedCaptchaPath } from 'components/page/Captcha/Captcha.paths'
import { CaptchaPageLoadable } from 'components/page/Captcha/CaptchaPageLoadable'
import { OnboardingLoadable } from 'components/page/Onboarding/OnboardingLoadable'
import {
  changeLanguagePath,
  emailResetSuccessPath,
  enterBirthdayPath,
  enterNamePath,
  finalStepPath,
  loginPath,
  phoneResetSuccessPath,
  restorePasswordEmailSuccessPath,
  restorePasswordEnterPhoneCodePath,
  restorePasswordPath,
  restorePasswordPhoneSuccessPath,
  selectDatingPurposePath,
  selectGenderPath,
} from 'components/page/Onboarding/paths'
import { promoCodeRoutes } from 'components/page/PromoCode/promoCode.routes'
import { SocialAuthorizationFailLoadable } from 'components/page/SocialAuthorization/SocialAuthorizationFailLoadable'
import { SocialAuthorizationSuccessLoadable } from 'components/page/SocialAuthorization/SocialAuthorizationSuccessLoadable'
import {
  socialAuthorizationFailFullPath,
  socialAuthorizationSuccessPath,
} from 'components/page/SocialAuthorization/socialAuthorization.paths'
import { EnterEmail } from 'components/page/StepRegistration/step/EnterEmail'
import {
  appAgreementIndexPath,
  appConfidentialityPath,
  confidentialityPath,
  enterEmailPath,
  indexPath,
} from 'components/paths'
import { LocaleSwitch } from 'components/system/LocaleSwitch'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React from 'react'
import { useRouteMatch } from 'react-router'
import { stepRegistrationReducer } from 'reducers/registration/stepRegistrationReducer'

export const OnboardingSwitch = () => {
  useReducersInsert({ stepRegistration: stepRegistrationReducer })

  const match = useRouteMatch()
  const { partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partnerId } }) => ({
      partnerId,
    })
  )

  if (!match || !partnerId) {
    return null
  }

  return (
    <CreateBaseUrl
      contextId={landingLayoutContextId}
      baseUrl={match.url}
      basePath={match.path}
    >
      <LocaleSwitch
        matchUrl={match.url}
        routes={[
          {
            path: socialAuthorizationFailFullPath,
            component: SocialAuthorizationFailLoadable,
          },
          {
            path: socialAuthorizationSuccessPath,
            component: SocialAuthorizationSuccessLoadable,
          },
          {
            path: unauthorizedCaptchaPath,
            component: CaptchaPageLoadable,
          },
          ...promoCodeRoutes,
          {
            path: enterEmailPath,
            component: EnterEmail,
          },
          ...sliderRoutes.map((route) => ({
            path: route,
            component: OnboardingLoadable,
            key: 1, // Используем одинаковый key, чтобы компоненты не делали ремаунт
          })),
        ]}
      />
    </CreateBaseUrl>
  )
}

export default OnboardingSwitch

/** Все эти пути обрабатывает Onboarding внутри себя */
const sliderRoutes = [
  loginPath,
  restorePasswordPath,
  restorePasswordEmailSuccessPath,
  restorePasswordPhoneSuccessPath,
  restorePasswordEnterPhoneCodePath,
  phoneResetSuccessPath,
  emailResetSuccessPath,
  selectGenderPath,
  enterNamePath,
  enterBirthdayPath,
  selectDatingPurposePath,
  finalStepPath,
  changeLanguagePath,
  appAgreementIndexPath,
  confidentialityPath,
  appConfidentialityPath,
  changeLanguagePath,
  cityListPath,
  shortcutListPath,
  indexPath,
]
